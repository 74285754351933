import React from 'react';
import * as d3 from 'd3';
import { Row, Col } from 'reactstrap';

import {
  ImpactDistribution,
  Accordion,
  SessionClassTable,
  BasicHeader,
  OverviewTable,
  Sidebar,
  NavPane,
  StrokeMap2,
  score,
  sessionGraph,
  utilities,
  tennisUtilities,
} from 'cuemate-charts';

/* Functions */
const { getHoursAndMinutes } = utilities;
const {
  getGroups,
  getTypes,
  getMetrics,
  getSessionClassStats,
  getPairedUnit,
  getSessionMovement,
  ServeTypes,
  getCoreClasses,
} = tennisUtilities;

export default function configuration({
  elements,
  filteredSets,
  setFilters,
  sessionData,
  onTransition,
  activeContent,
  config,
  reportType,
  units,
  cmvThresholds,
}) {
  const classKey = 'classification';
  const { sessions, availableClasses } = sessionData;
  const allMetrics = getMetrics([], units)
    .map((d) => d.value)
    .flat();
  const scales = ['pace', 'spin'].map((d) => {
    const metric = allMetrics.find((m) => m.name === d);
    return {
      name: d,
      scale: metric.scale ? metric.scale : 1,
    };
  });
  const { classAttributes } = config.parameters;
  const regexUnit = /\[.+\]/;
  const updatedAttributes = classAttributes.map((attribute) => {
    const updatedAttribute = { ...attribute };
    const stringSearch = attribute.label.match(regexUnit);
    if (stringSearch) {
      const unitString = stringSearch[0];
      const unit = unitString.slice(1, unitString.length - 1);
      if (!units.includes(unit)) {
        // The unit is not as specified and needed to be updated.
        const { label, scale } = getPairedUnit(unit, units);
        if (units.includes(label)) {
          updatedAttribute.label = updatedAttribute.label.replace(unit, label);
          updatedAttribute.scale = scale;
        }
      }
    }
    return updatedAttribute;
  });

  const sidebarItems = sessions.map((session) => ({
    name: session.datetime,
    label: session.dateText,
  }));

  let sessionId = 0;
  if (activeContent) {
    const tempId = sidebarItems.findIndex((d) => activeContent.includes(d.name));
    if (tempId >= 0) sessionId = tempId;
  }
  const activeSession = sessions[sessionId];
  const {
    sets,
    count,
    countForehand,
    impactSuccessRate,
    location,
    partner,
    partnerName,
    datetime,
  } = activeSession;

  // Filter elements by selected sets
  const selectedSets = sets
    .filter((_, i) => !filteredSets.includes(i))
    .map((s) => (s.datetimeUTC ? [s.datetime, s.datetimeUTC] : [s.datetime]))
    .flat();
  const selectedElements = elements
    .filter((d) => selectedSets.includes(d.set))
    .sort((a, b) => (a.datetime < b.datetime ? -1 : 1));

  const updatedClassStats = getSessionClassStats(selectedElements);
  // Get the activity type (tennis or pickleball) of the session currently being viewed
  let activityType = 'tennis';
  if (activeSession.type) {
    activityType = activeSession.type;
  }
  const strokeTypes = getTypes(classKey, activityType);
  const types = strokeTypes[0]; // Top-level classification

  const headerInfo = {
    title: activeSession.dateText,
    subtitle: `Session ${sessions.length - sessionId}`,
    subContent: { chart: score, score: activeSession.score },
    classes: 'blue',
  };

  const playDuration = getHoursAndMinutes(activeSession.playDuration);
  const totalDuration = getHoursAndMinutes(activeSession.duration);
  const rallyLength = d3.format('.1f')(activeSession.rallyLength);
  const sessionLocation = location ? location.match(/\w.*[A-Za-z]/g)[0] : '-';

  const opponentType = partner && partner !== 'self' ? partner : 'partner';
  let opponentName = '-';
  if (partner) {
    opponentName = partner === 'self' ? 'self' : partnerName;
  }
  const { movementScore, movementCount } = getSessionMovement(
    cmvThresholds[0].threshold,
    selectedElements
  );
  const overviewTableItems = [
    {
      label: 'DURATION',
      value: `${playDuration}/${totalDuration}`,
      tooltip: [`Play: ${playDuration}`, `Total: ${totalDuration}`, `Rally Length: ${rallyLength}`],
    },
    {
      label: 'ELEMENTS',
      value: count,
      tooltip: [`Forehand: ${countForehand}`, `Backhand: ${count - countForehand}`],
    },
    {
      label: 'SWEET SPOT',
      value: `${d3.format('.1f')(impactSuccessRate * 100)}%`,
    },
    {
      label: 'COURT MOVEMENT',
      value: `${d3.format('.2f')(movementScore)}`,
      tooltip: [
        `Low: ${movementCount.low}`,
        `Medium: ${movementCount.medium}`,
        `High: ${movementCount.high}`,
      ],
    },
    { label: 'LOCATION', value: sessionLocation },
    { label: opponentType.toUpperCase(), value: opponentName },
    {
      label: 'SETS',
      value: sets.length,
      click: {
        type: 'table',
        selection: 'multiple',
        keys: ['#', 'Time', 'Play Type', 'Strokes', 'Play Modality', 'Cue', 'Tags', 'Notes'],
        values: sets.map((s, i) => [
          i + 1,
          d3.timeFormat('%I:%M %p')(d3.timeParse('%Y-%m-%d--%H-%M-%S')(s.datetime)),
          s.typeRaw || '-',
          s.count || '-',
          s.playModalityRaw || '-',
          s.subTypeRaw || '-',
          s.tags || '-',
          s.note || '-',
        ]),
      },
    },
  ];

  const navbarItems = types.map((type) => {
    // eslint-disable-next-line no-shadow
    const count = selectedElements.filter((d) => d.classification.startsWith(type)).length;
    return {
      name: type,
      label: type.toUpperCase(),
      count,
      disabled: count < 10,
    };
  });

  // Update metrics with units
  const optionMetrics = [
    'pace',
    'spin',
    'envelope_efficiency',
    'spin_efficiency',
    'impact_variability',
    'impact_success',
  ];
  if (reportType === 'experiment' && 'cmv_dist' in selectedElements[0]) {
    optionMetrics.push('cmv_dist');
  }
  const options = optionMetrics.map((d) => allMetrics.find((m) => m.name === d));

  const contentOptions = (index) => {
    if (selectedElements.length === 0) return [];
    const type = types[index];
    const typeElements = selectedElements.filter((d) => d.classification.startsWith(type));
    if (typeElements.length === 0) return [];
    const availableHands = d3.map(typeElements, (d) => d.classification.split(' ')[1]).keys();
    const hands = [...strokeTypes[1]].reverse().filter((hand) => availableHands.includes(hand));
    const techs = index === 1 ? ServeTypes[2] : strokeTypes[2];
    const coreClasses = getCoreClasses(typeElements, classKey, reportType);

    // Rows of Impact Charts
    const gap = Math.floor((12 % techs.length) / 2);
    const impactRows = hands.map((hand) => (
      <Row key={hand} className="text-center">
        <Col
          xs={{
            size: techs.length > 4 ? 12 : 10,
            offset: techs.length > 4 ? 0 : 1,
          }}
          key={hand}
        >
          <h4 className="tracking p-0 m-0 mt-2">{hand}</h4>
          <Row>
            {techs.map((tech, ti) => {
              let impactData = typeElements
                .filter((d) => d[classKey].includes(`${hand} ${tech}`) && d.impact_success)
                .map((d) => ({ x: d.impact_z, y: d.impact_y }));
              if (impactData.length < 10) impactData = [];
              return (
                <Col
                  xs={{
                    size: Math.floor(12 / techs.length),
                    offset: ti === 0 ? gap : 0,
                  }}
                  key={`${hand} ${tech}`}
                  className="d-flex flex-column"
                >
                  <p className="tracking my-0">{tech}</p>
                  <ImpactDistribution data={impactData} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    ));

    const accordionItems = [
      {
        title: 'Stroke Map',
        width: 12,
        component: StrokeMap2,
        elements: typeElements,
        comparison: sessions,
        sessionId,
        type,
        digit: true,
        session: true,
        classKeys: [classKey],
        activityType,
        coreClasses,
        scales,
      },
      {
        title: 'Most Used Classes in the Session',
        component: SessionClassTable,
        sessions,
        sessionId,
        type,
        onTransition,
        attributes: updatedAttributes,
        minCount: 20,
        availableClasses,
        updatedClassStats,
      },
      {
        title: 'Session Trend',
        chart: sessionGraph,
        elements: selectedElements,
        type,
        options,
        // pass the activityType to getGroups function of tennisUtilities to correctly display stroke class labels
        groups: index === 1 ? getGroups('serveType') : getGroups(classKey, activityType),
        cmvThresholds,
        classKey,
        // pass the activity type to session trend component
        activityType,
        successTag: 'impact_success',
      },
      {
        title: 'Impact Distribution',
        // eslint-disable-next-line react/jsx-no-useless-fragment
        content: <>{impactRows}</>,
      },
    ];
    return <Accordion items={accordionItems} mode={0} classes="grey-bg" />;
  };

  return [
    {
      width: 3,
      component: Sidebar,
      items: sidebarItems,
      sticky: 100,
      activeContent: datetime,
      onTransition,
    },
    {
      width: 9,
      items: [
        [{ component: BasicHeader, ...headerInfo }],
        [
          {
            component: OverviewTable,
            items: overviewTableItems,
            onTransition: (d) => setFilters(d3.range(d.length).filter((i) => !d[i])),
          },
        ],
        [
          selectedElements.length === 0
            ? { content: <br /> }
            : {
                component: NavPane,
                items: navbarItems,
                classes: 'padding-top-25',
                contentOptions,
              },
        ],
      ],
    },
  ];
}

import React from 'react';
import { Col, Container, Row, Table, UncontrolledCarousel } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import humanaugmentation from '../../../images/home/humanaugmentation.jpg';
import trainingloop from '../../../images/home/trainingloop.png';
import trainingstack from '../../../images/home/trainingstack.png';
import deepLoop from '../../../images/home/stroke-ensemble.png';
import repertoireTree from '../../../images/home/repertoire-tree.png';
import strokePatternPhases from '../../../images/background/stroke-pattern-phases.png';
import cueing from '../../../images/concepts/cueing.png';
import reviewScreen from '../../../images/landingpage/Stroke_repertoire.PNG';
import seamlessTraining from '../../../images/landingpage/Seamless_Training.svg';
import classReport from '../../../images/screens/ClassReport-Overview-Radar.PNG';
import trainingGoalStatus from '../../../images/screens/TrainingGoal-Status.PNG';
import rating from '../../../images/screens/player-profile/PlayerProfile-Training.png';
import strokeRepertoireProcessing1 from '../../../images/overview/stroke-and-repertoire-processing-01.png';
import strokeRepertoireProcessing2 from '../../../images/overview/stroke-and-repertoire-processing-02.png';
import strokeRepertoireProcessing3 from '../../../images/overview/stroke-and-repertoire-processing-03.png';
import strokeRepertoireProcessing4 from '../../../images/overview/stroke-and-repertoire-processing-04.png';
import webReports from '../../../images/overview/web-reports.png';

import './GatedMainHome.css';

function WayBeyondFitnessTracking() {
  return (
    <Container className="padding-bottom-25">
      <Row className="text-center">
        <Col sm={12}>
          <h2 className="blueGrey">Way Beyond Fitness Tracking</h2>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm={{ size: 5 }}>
          <h3>Augmented Training and Learning</h3>
          <p>
            Human skills cannot be programmed but have to be learned. Therefore, the only way to get
            better is to practice. Therefore, the central question is the quality of your practice.
            CueMate is the first ecosystem designed to enhance your natural movement learning using
            data.
          </p>

          <h4>What does it mean to augment learning? </h4>
          <p>
            Technology can help learning skills at different levels. CueMate focuses on four primary
            aspects:
          </p>
          <Table bordered size="sm" responsive hover>
            <tbody>
              <tr>
                <td>
                  <img
                    src={trainingstack}
                    className="d-block mx-auto img-fluid"
                    alt="CueMate Training Loop"
                    width="45%"
                  />
                </td>
                <td style={{ textAlign: 'left' }}>
                  <ol>
                    <li>Knowledge: help you understand your current skills.</li>
                    <li>
                      Diagnosis: help you understand what aspect of your skills you need to change
                      to get to the next level.
                    </li>
                    <li>
                      Training: help you take your training on the court and focus on the right
                      aspects of your movements.
                    </li>
                    <li>Feedback: help you know if you are doing the right thing in the moment.</li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </Table>
          <h3 className="padding-top-15">CueMate Ecosystem</h3>
          <p>
            These primary capabilities are realized with CueMate’s ecosystem, which has three
            primary components:{' '}
          </p>
        </Col>
        <Col sm={7}>
          <img
            src={humanaugmentation}
            className="d-block mx-auto img-fluid"
            alt="CueMate Training Loop"
          />
        </Col>
      </Row>

      <Row>
        <Table bordered style={{ textAlign: 'center' }} size="sm" striped responsive hover>
          <thead>
            <tr>
              <th>The CueMate Racket Sensor</th>
              <th>Mobile and Web Apps</th>
              <th>Motion Cloud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Captures high-definition motion data for real-time stroke analysis.</td>
              <td>
                Makes data actionable and supports deliberate training augmented by real-time
                feedback.
              </td>
              <td>
                Combines your data with other players to compute the skill rating and reference
                ranges.
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          {' '}
          For more details, check out the{' '}
          <Link to="/background/cuemate-ecosystem">
            CueMate Ecosystem <i className="fa fa-angle-right" />
          </Link>{' '}
        </p>
      </Row>
      <Row className="padding-top-15">
        <Col>
          <p>
            The rest of this overview gives you some additional information about how CueMate works
            so that to start using CueMate and make the most of it.
          </p>
          <ol>
            <li>
              <Link smooth to="#movement-model">
                CueMate’s Movement Model:
              </Link>{' '}
              How CueMate processes the sensor measurements to enable you to acquire knowledge about
              your skills.
            </li>
            <li>
              <Link smooth to="#skill-model">
                Skill Model:
              </Link>{' '}
              How CueMate translates the movement data into a program for skill improvement.
            </li>
            <li>
              <Link smooth to="#training-features">
                Training Features:
              </Link>{' '}
              The tools that help you execute one the training program.
            </li>
            <li>
              <Link smooth to="#benefits-of-using-cuemate">
                Benefits of Using CueMate:
              </Link>{' '}
              What to expect if you invest yourself in using CueMate regularly.
            </li>
          </ol>
          <p>
            {' '}
            For more details, check out the{' '}
            <Link to="/background">
              Background <i className="fa fa-angle-right" />
            </Link>{' '}
          </p>

          <p>
            Each of the sections below will point to the relevant backgrounds if you are interested
            and motivated to learn more. And finally, check the important{' '}
            <Link to="/key-concepts">Concepts</Link>.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function MovementModel() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25" id="movement-model">
      <Container>
        <Row className="text-center">
          <Col sm={12}>
            <h2 className="blueGrey">CueMate’s Movement Model</h2>
          </Col>
        </Row>
        <Row className="padding-top-15">
          <h4>
            The foundation to effective data-driven training is using the right movement models.
            CueMate models your movement performance using a so-called hierarchical model which can
            be summarized as follows:
          </h4>
        </Row>
        <Row className="padding-top-15 justify-content-center">
          <Col sm="7">
            <UncontrolledCarousel
              dark
              items={[
                {
                  key: 1,
                  src: strokeRepertoireProcessing1,
                },
                {
                  key: 2,
                  src: strokeRepertoireProcessing2,
                },
                {
                  key: 3,
                  src: strokeRepertoireProcessing3,
                },
                {
                  key: 3,
                  src: strokeRepertoireProcessing4,
                },
              ]}
              className="img-border"
            />
          </Col>
        </Row>

        <Row className="padding-top-25">
          <Col sm={6}>
            <h3>Hierarchical Model</h3>
            <p>
              The way CueMate processes the movement data is a hierarchical model. What this means
              is that there are several levels of analysis. This is not just a design or
              mathematical decision but reflects how the movement is organized in the brain.
            </p>
            <Table responsive hover striped bordered size="sm">
              <tbody>
                <tr>
                  <th scope="row">
                    <ul>
                      <li>
                        At the top level, the repertoire, which is your vocabulary and is organized
                        in stroke categories.{' '}
                      </li>
                      <li>Then each stroke type is as a class.</li>
                      <li>And finally different spin and intensity classes.</li>
                    </ul>
                  </th>
                  <td style={{ width: '33%' }}>
                    <img
                      src={repertoireTree}
                      className="d-block mx-auto img-fluid"
                      alt="CueMate Training Loop"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <ul>
                      <li>
                        CueMate combines the strokes that are similar in technique and outcome.
                      </li>
                      <li>
                        This makes it possible to analyze them as a group and powerful mathematical
                        tools.{' '}
                      </li>
                    </ul>
                  </th>
                  <td>
                    <img
                      src={deepLoop}
                      className="d-block mx-auto img-fluid"
                      alt="CueMate Training Loop"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <ul>
                      <li>And then finally for each class, the stroke architecture.</li>
                      <li>
                        Complex movements like the stroke are created by stringing together several
                        sub-movements or phases.{' '}
                      </li>
                    </ul>
                  </th>
                  <td>
                    <img
                      src={strokePatternPhases}
                      className="d-block mx-auto img-fluid"
                      alt="CueMate Training Loop"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className="padding-top-25">
              {' '}
              <Link to="/background/how-cuemate-works">
                Learn More About How CueMate Works <i className="fa fa-angle-right" />{' '}
              </Link>
            </p>
          </Col>
          <Col>
            <h3>Understand Your Strokes</h3>
            <Table striped responsive hover bordered size="sm">
              <tbody>
                <tr>
                  <th scope="row">Your repertoire of strokes.</th>
                  <td>
                    <ul>
                      <li>
                        Identifies the different stroke types that form your individual stroke
                        repertoire.{' '}
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Each stroke types in your repertoire.</th>
                  <td>
                    <ul>
                      <li>Range of outcomes (spin, speed)</li>
                      <li>Strike quality (how well you setup to strike the ball) </li>
                      <li>Movement technique (e.g., backswing, back loop, forward swing, etc.)</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className="padding-top-25">
              {' '}
              <Link to="/background/hierarchical-movement-skill-model">
                Learn More About The Movement and Skill Model <i className="fa fa-angle-right" />{' '}
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function DataThatsActionable() {
  return (
    <Container className="padding-top-25 padding-bottom-25" id="skill-model">
      <Row className="text-center">
        <Col sm={12}>
          {' '}
          <h2 className="blueGrey"> Data That’s Actionable: Skill Model </h2>
        </Col>
      </Row>
      <Row className="padding-25">
        <Col sm={12}>
          <h3>
            CueMate makes data actionable—every stroke contributes to knowledge about your skills
            and performance, which in turn support training interventions.
          </h3>
          <h3 className="padding-top-25">Diagnose and Elevate Your Skills</h3>
          <h4>
            CueMate’s skill model makes it possible to achieve a comprehensive and detailed analysis
            of your tennis skills.
          </h4>
          <h4>
            These insights can then be turned into training interventions that will help take your
            skills to the next level.
          </h4>
        </Col>
      </Row>

      <Row className="padding-bottom-25">
        <Table bordered size="sm" striped hover responsive>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th style={{ width: '50%' }}>Repertoire Level</th>
              <th>Benefits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                The Stroke Repertoire—or Stroke Map—organizes your strokes into distinct classes and
                enables understanding and navigating your stroke vocabulary.
              </td>
              <td>
                <ul>
                  <li>
                    Understand your core stroke classes and how they contribute to your current
                    skill level and game style.
                  </li>
                  <li>
                    Identify the major gaps in your repertoire to help improve your versatility and
                    competitiveness.
                  </li>
                  <li>
                    See how you use your repertoire in games to make sure you play on your
                    strengths.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src={reviewScreen}
                  className="d-block mx-auto img-fluid padding-top-10"
                  alt="Stroke Map in Review"
                  width="50%"
                />
              </td>
              <td>
                The review screen allows you to quickly see your strength and weaknesses across the
                stroke repertoire.
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>

      <Row className="padding-bottom-25">
        <Table bordered size="sm" striped hover responsive>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th style={{ width: '50%' }}>Class Level</th>
              <th>Benefits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                The Stroke Classes reflect the main patterns you use to execute your strokes. They
                help get detailed insights into your technique and the stroke outcomes.
              </td>
              <td>
                For each Stroke Class:
                <ul>
                  <li>Understand their performance including their range of outcomes.</li>
                  <li>
                    Track variations and the highs and lows of your technique and performance under
                    different play conditions.
                  </li>
                  <li>
                    Identify specific aspects of your strokes&apos; technique that improve their
                    outcomes.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src={classReport}
                  className="d-block mx-auto img-fluid padding-top-10"
                  alt="Class Report"
                  width="50%"
                />
              </td>
              <td>
                The class report screen allows you to get into the details of each stroke class.
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>

      <Table bordered size="sm" striped hover responsive>
        <thead style={{ textAlign: 'center' }}>
          <tr>
            <th style={{ width: '50%' }}>Population Level</th>
            <th>Benefits</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              To turn the movement models into meaningful insights, CueMate combines the data and
              models from our user population.
              <br />
              <br />
              The data is then used to create references values to help interpretate your data.
            </td>
            <td>
              Reference ranges for how the strokes are distributes across the repertoire for
              different skill levels.
              <ul>
                <li>What core strokes for a novice, beginner, intermediate.</li>
              </ul>
              Reference ranges for the stroke outcomes
              <br />
              Best technique for different skill levels and styles of play.
            </td>
          </tr>
        </tbody>
      </Table>
      <p className="padding-top-15">
        {' '}
        <Link to="/background/hierarchical-movement-skill-model">
          Learn More About The Movement and Skill Model <i className="fa fa-angle-right" />{' '}
        </Link>
      </p>
    </Container>
  );
}

function TrainingWithCueMate() {
  return (
    <Container
      fluid
      className="padding-top-25 padding-bottom-25 light-grey-bg"
      id="training-features"
    >
      <Container>
        <Row className="text-center">
          <Col sm={12}>
            <h2 className="blueGrey"> Training with CueMate </h2>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col>
            <h3>
              Finally, CueMate’s training features help you take your tennis skills to the next
              level.
            </h3>
            <h4 className="padding-top-15">
              The CueMate ecosystem provides features to monitor and train your skills at different
              levels.
            </h4>
            <ul>
              <li>
                <b>Assessment and diagnostics</b> tools to help identify gaps and deficiencies
                across the repertoire as well as the stroke classes.
              </li>
              <li>
                The <b>Rating System</b> that captures your individual style and skill level.
              </li>
              <li>The Rating System that captures your individual style and skill level.</li>
              <li>
                <b>Training goals</b> to help you focus and track training on what’s critical for
                your progress.
              </li>
              <li>
                <b>Feedback</b> to help reinforce key movement features for more efficient training.
              </li>
            </ul>
          </Col>
          <Col sm={3}>
            <img src={trainingloop} className="d-block mx-auto img-fluid" alt="Training Loop" />
          </Col>
          <p className="padding-top-15">
            {' '}
            <Link to="/background/learning-model">
              Learn More About Deliberate Training <i className="fa fa-angle-right" />{' '}
            </Link>
          </p>
        </Row>

        <h3 className="padding-top-25 padding-bottom-10 blue"> Assessment and Diagnostics </h3>
        <Table striped responsive hover bordered size="sm">
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th style={{ width: '33%' }}>Before your Sessions</th>
              <th style={{ width: '33%' }}>During your sessions</th>
              <th>After your Sessions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Setup your session with the right intentions and focus</td>
              <td>Monitor your overall activity level, the duration and volume of stroke.</td>
              <td>Get a summary of important session trends.</td>
            </tr>
            <tr>
              <td />
              <td>Know what strokes you are using most and their outcomes.</td>
              <td>Review the main trends across the stroke classes&apos; outcomes. </td>
            </tr>
            <tr>
              <td>
                Review your training goals. Pin the goals that are most important for this session.
                Review the training tips so you know what.
              </td>
              <td>Use cueing to get feedback on pinned training goals. </td>
              <td>Review your training goals.</td>
            </tr>
          </tbody>
        </Table>
        <p>
          {' '}
          <Link to="/background/hierarchical-skill-assessment-and-training">
            Learn More About Skill Assessment and Training <i className="fa fa-angle-right" />{' '}
          </Link>
        </p>

        <Row className="padding-top-25">
          <Col sm={8}>
            <h3 className="blue">Rating System</h3>
            <h4>
              Learning is a process. To improve in sports like tennis you have to follow a path that
              is adapted to your skills, goals, and personal characteristics.
            </h4>
            <h4>
              The rating system takes away guess work and is used to generate training goals
              automatically to help lead you on your path to becoming a better player.
            </h4>
          </Col>
          <Col sm={4}>
            <img src={rating} className="d-block mx-auto img-fluid" alt="Player Rating Screen" />
          </Col>
        </Row>
        <p>
          {' '}
          <Link to="/background/learning-model">
            Learn More About CueMate&apos;s Learning Model <i className="fa fa-angle-right" />{' '}
          </Link>
        </p>

        <Row className="padding-top-25">
          <Col sm={4}>
            <img
              src={trainingGoalStatus}
              className="d-block mx-auto img-fluid"
              alt="Training Goal Status Screen"
            />
          </Col>
          <Col sm={8}>
            <h3 className="blue">Training Goals</h3>
            <h4>
              CueMate generates individualized training goals based on the skill assessments and
              diagnostics.
            </h4>
            <h4>
              Training goals help you help you focus and work on the aspects that are most critical
              for your current skill level.
            </h4>
            <h4>
              {' '}
              The training goals can cover attributes across the entire model hierarchy, from the
              repertoire, down to specific features of your stroke technique.
            </h4>
          </Col>
        </Row>

        <Row className="padding-25">
          <Col sm={6}>
            <h3 className="blue">Real-time Cueing: The &#39;Cue&#39; in CueMate is for Cueing. </h3>
            <h4>
              {' '}
              Feedback about your performance is critical to efficient learning. CueMate provides
              real-time feedback on a range of features, including:
            </h4>
            <ul>
              <li>Validates your outcome levels such as the amount of spin or ball speed. </li>
              <li>
                Validates specific movement features such as keeping your racket face closed during
                the back loop.
              </li>
              <li>And a nearly unlimited set of movement features to reinforce/avoid.</li>
            </ul>
            Cueing will change the way you experience training. You can also use cueing during play
            or games to boost your confidence on key aspects of your technique.
          </Col>
          <Col sm={6}>
            <img src={cueing} className="d-block mx-auto img-fluid" alt="Cueing" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function CueMateBenefits() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25" id="benefits-of-using-cuemate">
      <Container>
        <Row className="text-center">
          <Col sm={12}>
            <h2 className="blueGrey">Benefits of Using CueMate</h2>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm={6}>
            <img
              src={seamlessTraining}
              className="d-block mx-auto img-fluid width-80"
              alt="Training Loop"
              width="50%"
            />
          </Col>
          <Col sm={6}>
            <h3>CueMate creates a virtuous cycle to keep you on the path to success.</h3>
            <h4>
              As you accumulate more data, CueMate’s assessment becomes more complete and precise,
              setting you up for further improvement.
            </h4>
            <h4>
              You can engage with CueMate at different times and different ways: completely hands
              off, or more intensive, such as when training with a ball machine, partner, or coach.
            </h4>
            <br />
            <h4>
              Using CueMate regularly helps you know your skills and make decisions about training:
            </h4>
            <ul>
              <li>
                You can benefit from CueMate at any level, learning stage, and whether you are
                playing for fun or training with a coach.
              </li>
              <li>Train systematically with data-driven insights on your skills.</li>
              <li>Understand if your training is working and how quickly you are progressing.</li>
              <li>
                Or simply monitor your activity and learn to become smarter about how you use your
                skills and maybe CueMate will help you become more deliberate about your training.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function More() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25 light-grey-bg">
      <Container>
        <Row className="text-center">
          <Col sm={12}>
            <h2 className="blueGrey">More</h2>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col>
            <h3>
              CueMate has even more features to discover that help you leverage data and the tennis
              community to stay motivated and achieve your goals.
            </h3>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm={6}>
            <h3 className="blue">CueMate Web App</h3>
            <h4>
              Use the CueMate web app to gain in-depth knowledge about your skill rating, how you
              compare to other players, check the leaderboard, and status of your progress.
            </h4>
          </Col>
          <Col sm={{ size: 4, offset: 1 }}>
            <img
              src={webReports}
              className="d-block mx-auto img-fluid padding-top-10"
              alt="Web Report"
            />
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm={6}>
            <h3 className="blue">Social Features</h3>
            <h4>
              The CueMate ecosystem also includes features to compare yourself with others or get
              inspired and motivated by seeing other players skill profiles.
            </h4>
          </Col>
        </Row>
        <p>
          {' '}
          <Link to="/cuemate-web">
            Learn More About CueMate&apos;s Web-App <i className="fa fa-angle-right" />{' '}
          </Link>
        </p>
      </Container>
    </Container>
  );
}

export default function GatedWelcome() {
  return (
    <div>
      <WayBeyondFitnessTracking />
      <MovementModel />
      <DataThatsActionable />
      <TrainingWithCueMate />
      <CueMateBenefits />
      <More />
    </div>
  );
}

/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Accordion,
  BasicLayout,
  ClassRanking,
  ComparisonSelectionV2,
  envelope,
  groupUtilityMap,
  ImpactDistribution,
  NavPane,
  OverviewTable,
  ratingTrend,
  SkillProfile,
  SkillStatus,
  stackedTrend,
  StatsSummary,
  statsTrend,
  StrokeMap,
  tennisUtilities,
} from 'cuemate-charts';

const {
  getMetrics,
  getTypes,
  ServeTypes,
  SKILL_STATUS,
  utilityPartitions,
  getRepertoireStats,
  RANKING_LEVELS,
  getCoreClasses,
} = tennisUtilities;

function RepertoireWrapper({
  refId,
  toggleComparison,
  comparisons,
  typeElements,
  type,
  classKeys,
  references,
  coreClasses,
  scales,
}) {
  return (
    <>
      <div className="pr-2 pt-3">REFERENCE: </div>
      <ComparisonSelectionV2
        id={refId}
        toggleComparison={toggleComparison}
        comparisons={comparisons}
      />
      <StrokeMap
        width={10}
        elements={typeElements}
        type={type}
        classKeys={classKeys}
        references={references}
        coreClasses={coreClasses}
        scales={scales}
      />
    </>
  );
}

export default function configuration(data) {
  const {
    elements,
    classData,
    reportType,
    onTransition,
    refRanges,
    classStats,
    activityData,
    rating,
    units,
  } = data;

  const classKey = 'classification';
  // get the most recent session from the activityData
  const mostRecentSession = Object.values(activityData).slice(-1)[0];
  let activityType = 'tennis';
  if (mostRecentSession.type) {
    activityType = mostRecentSession.type;
  }
  const strokeTypes = getTypes(classKey, activityType);
  const types = strokeTypes[0]; // Top-level classification
  const hands = strokeTypes[1];
  let techs = strokeTypes[2];
  const { overallStats } = data.sessionData;
  const allSessions = Object.values(activityData).map((session) => {
    const typeStats = {};
    types.forEach((t) => {
      const typeProfiles = session.classProfiles.filter((c) => c.classification.includes(t));
      typeStats[t] = { count: d3.sum(typeProfiles, (c) => c.count) };
      const [groups, groupValues] = getRepertoireStats(typeProfiles, classKey);
      groupValues.forEach((v, i) => {
        typeStats[t][groups[i]] = v;
      });
    });
    return { ...session, typeStats };
  });
  const refId = data.refId ? data.refId : 0;
  const { history: ratingHistory } = rating;
  const activitySets = activityData
    ? Object.values(activityData)
        .map((d) => d.sets)
        .flat()
    : [];
  /* 1. Overview */
  const overviewTableItems = [
    {
      label: 'TOTAL SESSIONS',
      value:
        overallStats.sessionCount + (activityData ? `/${Object.keys(activityData).length}` : ''),
    },
    {
      label: 'STROKE COUNT',
      value:
        overallStats.count +
        // eslint-disable-next-line no-underscore-dangle
        (activityData ? `/${d3.sum(activitySets, (d) => d._numberStrokes)}` : ''),
    },
    {
      label: 'SWEET SPOT',
      value: `${d3.format('.1f')(overallStats.impactSuccess * 100)}%`,
    },
    {
      label: 'GLOBAL SCORE',
      value: overallStats.globalScore ? d3.format('.1f')(overallStats.globalScore * 100) : '-',
      jump: 'LEADERBOARD',
    },
  ];

  /* Reference Selection */
  const ranges = refRanges && refRanges[refId] && refRanges[refId].value;

  // Metrics are not specific for a certain class
  const metrics = getMetrics([], units);
  const scales = ['pace', 'spin'].map((d) => {
    const metric = metrics[0].value.find((m) => m.name === d);
    return {
      name: d,
      scale: metric.scale ? metric.scale : 1,
    };
  });

  /* 4. Accordion */
  const navbarItems = types.map((type) => {
    const count = elements.filter((d) => d[classKey].startsWith(type)).length;
    return {
      name: type,
      label: type.toUpperCase(),
      count,
      disabled: count < 100,
    };
  });

  const groups = refRanges ? refRanges.map((d) => d.key) : ['GLOBAL'];

  const contentOptions = (index) => {
    const type = types[index];
    const isServe = type === 'serve';
    const availableHands = isServe ? ['forehand'] : hands;
    const typeElements = elements
      .filter((d) => d[classKey].startsWith(type))
      .map((d) => {
        const res = { ...d };
        return res;
      });
    const typeClassData = classData.filter((c) => c.name.startsWith(type));
    if (isServe) {
      // eslint-disable-next-line prefer-destructuring
      techs = ServeTypes[2];
    }
    let allClasses =
      reportType === 'dataDriven'
        ? []
        : hands
            .map((h) => techs.map((t) => strokeTypes[3].map((s) => `${h} ${t} ${s}`)).flat())
            .flat();
    if (isServe) allClasses = allClasses.filter((c) => c.includes('forehand'));
    if (type === 'groundstroke') allClasses = allClasses.filter((c) => !c.includes('flat'));

    const userGroup = groups[refId] || 'GLOBAL';

    // Stats Trend
    const sessions = allSessions.filter((session) => session.typeStats[type].count >= 10);
    const defaultViewRange = [Math.max(-0.25, sessions.length - 30), sessions.length - 0.75];

    const [viewRange, setViewRange] = useState(defaultViewRange);
    const toggleUpdate = (d) => {
      if (
        viewRange === null ||
        Math.abs(viewRange[0] - d[0]) > 1e-3 ||
        Math.abs(viewRange[1] - d[1]) > 1e-3
      ) {
        setViewRange(d);
      }
    };

    useEffect(() => {
      setViewRange(defaultViewRange);
    }, [type]);

    // Utility Map
    const originalStats = classStats.filter(
      (d) =>
        d.classification.includes(type) &&
        (d.groups.includes(userGroup.toUpperCase()) || d.groups.includes(userGroup.toLowerCase()))
    );

    const groupStats = originalStats.filter((d) => !d.user);
    typeClassData
      .filter((d) => d.show)
      .forEach((c) => {
        const classInStats = originalStats.find((d) => d.user && d.classification === c.name) || {};

        groupStats.push({
          user: 'user',
          classification: c.name,
          spin: c.spin,
          pace: c.pace,
          skillStatus: c.skillStatus,
          score: classInStats.score,
        });
      });

    const utilityItems = [
      availableHands.map((g) => ({ width: 6, content: <p>{g.toUpperCase()}</p> })),
      availableHands.map((g) => ({
        width: 6,
        chart: groupUtilityMap,
        data: groupStats.filter((d) => d.classification.includes(g)),
        partitions: utilityPartitions.find(
          (d) => d.category.includes(type) && d.category.includes(g)
        ),
        units,
      })),
    ];

    // Impact Distribution
    const byGroup = d3
      .nest()
      .key((d) => {
        const words = d[classKey].split(' ');
        return words.slice(1, words.length - 1).join(' ');
      })
      .rollup((v) => v.filter((d) => d.impact_success))
      .entries(typeElements);

    const gap = Math.floor((12 % techs.length) / 2);
    const impactContent = (
      <Row className="text-center">
        {availableHands.map((hand) => (
          <Col xs="12" sm={techs.length > 4 ? '12' : '6'} key={hand}>
            <h4 className="tracking p-0 m-0 mt-2">{hand}</h4>
            <Row>
              {techs.map((t, ti) => {
                const group = byGroup.find((d) => d.key === `${hand} ${t}`);
                const impactData =
                  group && group.value.length >= 10
                    ? group.value.map((d) => ({ x: d.impact_z, y: d.impact_y }))
                    : [];
                return (
                  <Col
                    xs={{
                      size: Math.floor(12 / techs.length),
                      offset: ti === 0 ? gap : 0,
                    }}
                    key={`${hand} ${t}`}
                    className="d-flex flex-column"
                  >
                    <p className="tracking my-0">{t}</p>
                    <ImpactDistribution data={impactData} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        ))}
      </Row>
    );

    // Spin Envelope
    const envelopeElements = d3
      .nest()
      .key((d) => d[classKey].split(' ')[1])
      .rollup((v) =>
        techs.map((t) => ({
          key: t,
          value: v.filter((d) => d[classKey].includes(t)),
        }))
      )
      .entries(typeElements.filter((d) => d.impact_success));
    const envelopeGroups = availableHands.map((hand) => {
      const group = envelopeElements.find((e) => e.key === hand);
      return { key: hand, value: group && group.value };
    });
    const envelopeItems = [
      envelopeGroups.map((g) => ({ width: 6, content: <p>{g.key.toUpperCase()}</p> })),
      envelopeGroups.map((g) =>
        g.value ? { width: 6, chart: envelope, data: g.value, units } : { width: 6, content: <p /> }
      ),
    ];

    const classRefs = ranges && ranges.filter((d) => d.classification.includes(type));

    const classKeys = [classKey];
    const references = [classKey === 'classification' && classRefs.length > 0 ? classRefs : null];
    const coreClasses = getCoreClasses(typeElements, classKey, reportType);

    const accordionItems = [
      {
        title: 'History of Stroke Class Distributions',
        chart: stackedTrend,
        sessions,
        type,
        toggleUpdate,
        viewRange: viewRange[1] > sessions.length ? defaultViewRange : viewRange,
      },
      {
        title: 'Spin differentiation & Intensity Trend',
        chart: statsTrend,
        sessions,
        ranges: [1, strokeTypes[3].length],
        type,
        groups: [...hands].reverse(),
        toggleUpdate,
        viewRange: viewRange[1] > sessions.length ? defaultViewRange : viewRange,
      },
      {
        title: 'REPERTOIRE',
        component: RepertoireWrapper,
        typeElements,
        refId,
        toggleComparison: data.useRefId,
        comparisons: groups,
        type,
        classKeys,
        references,
        coreClasses,
        scales,
      },
      {
        title: 'Skill Profile',
        component: SkillProfile,
        data: typeClassData,
        onTransition,
        reference: classRefs.length === 0 ? null : classRefs,
        minStrokes: 100,
      },
      {
        title: 'Skill Status',
        component: SkillStatus,
        classData: typeClassData,
        allClasses,
        onTransition,
        SKILL_STATUS,
      },
      {
        title: 'Impact Distribution',
        content: impactContent,
      },
      {
        title: 'Overall Stats',
        component: StatsSummary,
        elements,
        classData: typeClassData,
        metrics,
      },
    ];

    if (ratingHistory && type === 'groundstroke') {
      accordionItems.splice(1, 0, {
        title: 'Rating Trend',
        chart: ratingTrend,
        sessions,
        type,
        groups: [...hands].reverse(),
        toggleUpdate,
        viewRange,
      });
    }

    const levelClasses = typeClassData.filter((c) => c.level !== undefined);
    if (['experiment', 'advanced'].includes(reportType) && levelClasses.length > 0) {
      accordionItems.splice(4, 0, {
        title: 'Class Outcome Rating',
        component: ClassRanking,
        classData: levelClasses,
        rankingLevels: RANKING_LEVELS,
      });
    }

    if (reportType === 'experiment') {
      accordionItems.push({
        title: 'Utility Map',
        component: BasicLayout,
        // items: [{ width: 6, chart: utilityMap, data: groupStats }]
        items: utilityItems,
        classes: 'text-center',
      });
      accordionItems.push({
        title: 'Spin Envelope',
        component: BasicLayout,
        items: envelopeItems,
        classes: 'text-center',
      });
    }

    return <Accordion items={accordionItems} mode={0} classes="grey-bg" />;
  };

  return [
    [{ component: OverviewTable, items: overviewTableItems, onTransition }],
    [{ component: NavPane, items: navbarItems, contentOptions }],
  ];
}

/* eslint-disable camelcase */
import React from 'react';
import * as d3 from 'd3';
import { Container, Row, Col } from 'reactstrap';

/* Charts */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Accordion,
  BasicHeader,
  BasicLayout,
  classHistogram,
  classSummaryTrend,
  classTrends,
  ComparisonSelectionV2,
  contourSpinPace,
  ImpactDistribution,
  MetricIcon,
  MetricTrend,
  motionPhases,
  NavPane,
  OverviewTable,
  PhaseDistribution, // envelope,
  phasePortrait,
  radar,
  Sidebar,
  strokeProfile,
  tennisUtilities,
} from 'cuemate-charts';

/* Functions */
const {
  getTypes,
  getStrokeProfile,
  getClassRadarData,
  getMetrics,
  getUnitScale,
  RANKING_LEVELS,
  processClassData,
} = tennisUtilities;

const comparisons = ['Past Session', 'Past 3 Sessions', 'Past 10 Sessions', 'All Past Sessions'];
const compareN = [1, 3, 10, 1000];
const minStrokes = 100;
const typeConversion = {
  groundstroke: 'GS',
  volley: 'VL',
  serve: 'SV',
  // pickleball stroke class types
  dink: 'DK',
  drive: 'DV',
  overhead: 'OH',
};

export default function configuration(data) {
  const { elements, activeContent, refRanges, reportType, onTransition, units, activityData } =
    data;
  let { classData } = data;
  const classKey = 'classification';
  // get the most recent session from the activityData
  const mostRecentSession = Object.values(activityData).slice(-1)[0];
  let activityType = 'tennis';
  if (mostRecentSession.type) {
    activityType = mostRecentSession.type;
  }
  /* FIXME: Using processClassData here instead of data.classData since data.classData does not update
  when reportType changes from basic to advanced and back */
  if (reportType === 'advanced') {
    classData = processClassData(elements, refRanges, classKey)
      .filter((c) => c.show)
      .sort((a, b) => {
        if (a.count <= minStrokes === b.count <= minStrokes) {
          return b.score - a.score;
        }
        return a.count <= minStrokes ? 1 : -1;
      });
  }

  const strokeTypes = getTypes(classKey, activityType)[0];
  const sidebarItems = strokeTypes.map((type) => {
    const typeClassData = classData.filter((cls) => cls.name.startsWith(type));
    return {
      name: type,
      label: type.toUpperCase(),
      items: typeClassData
        .sort((a, b) => b.count - a.count)
        .map((cls, i) => ({
          name: cls.name,
          label:
            (type === 'serve' ? cls.abrName.slice(9) : cls.abrName) +
            (cls.count <= minStrokes ? '*' : ''),
          index: i + 1,
        })),
    };
  });
  const sortedClassData = classData.sort((a, b) => b.count - a.count);
  let activeClassId = sortedClassData.findIndex((d) => d.name === activeContent.split(',')[1]);
  if (activeClassId < 0) {
    strokeTypes.some((d) => {
      const index = sortedClassData.findIndex((c) => c.name.includes(d));
      if (index >= 0) {
        activeClassId = index;
        return true;
      }
      return false;
    });
  }
  const activeClass = sortedClassData[activeClassId];
  const {
    name,
    abrName,
    spinEfficiency,
    paceEfficiency,
    envelopeEfficiency,
    count,
    score,
    impactSuccess,
    skillStatus,
    level,
    deficiency,
    useFrequency,
  } = activeClass;
  const activeTypeId = strokeTypes.findIndex((d) => name.includes(d));
  const activeType = strokeTypes[activeTypeId];

  // Header
  const idInGroup = sidebarItems[activeTypeId].items.findIndex((d) => d.name === name);
  // Overview
  // Rescale coefficients
  const spinE = spinEfficiency * getUnitScale(units[5]);
  const paceE = paceEfficiency * getUnitScale(units[3]);

  const overviewTableItems = [
    [
      { label: 'STROKE QUAL.', value: d3.format('.1f')(score * 10) },
      { label: 'STROKE COUNT', value: `${count} | ${d3.format('.1f')(useFrequency * 100)}%` },
      {
        label: 'SWEET SPOT',
        value: `${d3.format('.1f')(impactSuccess * 100)}%`,
      },
    ],
    [
      {
        label: 'SPIN COEF.',
        value: `${d3.format('.2f')(spinE)} ${units[5]}`,
      },
      {
        label: 'BALL SPEED COEF.',
        value: `${d3.format('.2f')(paceE)} ${units[3]}`,
      },
      {
        label: 'SPIN-BAll SPEED COEF.',
        value: d3.format('.2f')(envelopeEfficiency),
      },
    ],
  ];
  if (skillStatus) {
    overviewTableItems[0].push({
      label: 'SKILL STATUS',
      value: (
        <div>
          <span>{skillStatus.toUpperCase()}</span>
          {deficiency.map((d) => (
            <MetricIcon key={d.name} metric={d.name} />
          ))}
        </div>
      ),
    });
  }
  if (level !== undefined) {
    overviewTableItems[0].push({
      label: 'OUTCOME RATING',
      value: (
        <div>
          <span>{RANKING_LEVELS[level].toUpperCase()}</span>
        </div>
      ),
    });
  }

  // Overall Stats
  const classElements = elements.filter((d) => d[classKey] === name);
  const MIN_COUNT = 20;

  const backloopType = classElements.map((d) => d.forwardswing_type).filter((d) => d > 0);
  if (backloopType.length > 0) {
    overviewTableItems[1].push({
      label: 'BACKLOOP TYPE',
      value: d3.format('.2f')(d3.mean(backloopType)),
    });
  }

  const bySession = d3
    .nest()
    .key((d) => d.date)
    .rollup((v) => ({
      count: v.length,
      classes: d3
        .nest()
        .key((d) => d[classKey])
        .rollup((vc) => ({
          count: vc.length,
          sets: d3
            .nest()
            .key((d) => d.set)
            .rollup((vs) => ({
              count: vs.length,
              elements: vs,
            }))
            .entries(vc),
        }))
        .entries(v),
    }))
    .entries(elements);

  const byClass = bySession
    .map((s) => {
      const classID = s.value.classes.findIndex((c) => c.key === name);
      return {
        key: s.key,
        value: classID < 0 ? { count: 0, sets: [] } : s.value.classes[classID].value,
      };
    })
    .sort((a, b) => new Date(a.key) - new Date(b.key));

  /* NavPane */
  const availableClassRanges = [];
  if (refRanges) {
    refRanges.forEach((r) => {
      const classRef = r.value.find((d) => d.classification === name);
      if (classRef) {
        availableClassRanges.push({ group: r.key, ...classRef });
      }
    });
  }
  if (activeClass.levelRefRanges) {
    availableClassRanges.push(...activeClass.levelRefRanges);
  }
  if (activeClass.refRanges) {
    availableClassRanges.push(...activeClass.refRanges);
  }
  if (activeClass.typeRefRanges) {
    availableClassRanges.push(...activeClass.typeRefRanges);
  }
  // Reference ranges selection
  const refId = data.refId !== null && data.refId <= availableClassRanges.length ? data.refId : 0;
  const classRanges = availableClassRanges.length === 0 ? undefined : availableClassRanges[refId];
  const classRankingLevels = ['low', 'medium', 'high'].map((d) => '[classOutcomeLevel] '.concat(d));
  // Metrics are specific for a certain class
  const metrics = getMetrics(classRanges, units);

  const nTabs = d3.sum(compareN, (d) => byClass.length > d);
  const sessionTabs = d3.range(nTabs).map((d) => comparisons[d]);
  sessionTabs.push(`Past ${byClass.length} Sessions`);
  const comparedSessions = sessionTabs.map((_, i) => byClass.slice(-compareN[i]));
  const counts = comparedSessions.map((s) => d3.sum(s, (x) => x.value.count));
  const navbarItems = comparedSessions.map((_, i) => ({
    name: comparisons[i],
    label: sessionTabs[i],
    count: counts[i],
    disabled: counts[i] < MIN_COUNT,
  }));

  const contentOptions = (index) => {
    const selectedSessions = comparedSessions[index];
    const selectedElements = selectedSessions
      .map((s) => s.value.sets.map((d) => d.value.elements).flat())
      .flat();
    const reducedElements =
      selectedElements.length <= 500
        ? selectedElements
        : d3
            .range(500)
            .map((i) => selectedElements[Math.round((i * selectedElements.length) / 500)]);
    const radarMode = 1;
    const radarData = getClassRadarData(
      selectedElements,
      classRanges && [classRanges],
      radarMode,
      classKey,
      units
    );
    if (selectedElements.length < MIN_COUNT) return null;
    const accordionItems = [
      {
        title: 'STROKE QUALITY SCORE & IMPACT DISTRIBUTION',
        component: BasicLayout,
        items: [
          {
            width: 7,
            component: BasicLayout,
            items: [
              [{ chart: radar, ...radarData }],
              [
                {
                  content: (
                    <div className="w-100 text-center">
                      {`*Based on ${radarMode ? 'percentile of the group' : 'percentage'}`}
                    </div>
                  ),
                },
              ],
            ],
          },
          {
            width: 5,
            component: ImpactDistribution,
            data: selectedElements
              .filter((d) => d.impact_success)
              .map((d) => ({ x: d.impact_z, y: d.impact_y })),
          },
        ],
      },
      {
        title: 'Class Activity',
        chart: classHistogram,
        bySession: selectedSessions,
        elementName: 'stroke',
      },
    ];
    if (['experiment', 'coach'].includes(reportType)) {
      accordionItems.push({
        title: 'ATTRIBUTE TRENDS',
        component: MetricTrend,
        bySession: selectedSessions,
        metrics,
      });
    } else {
      const selectedMetrics = [
        'spin',
        'pace',
        'impact_success',
        'angle_of_attack',
        'arc_length',
        'spin_efficiency',
        'envelope_efficiency',
        'backloop openness',
        'forward-swing lag',
      ];
      metrics.forEach((g) => {
        accordionItems.push({
          title: g.key,
          chart: classTrends,
          bySession: selectedSessions,
          options: g.value.filter((d) => selectedMetrics.includes(d.name)),
        });
      });
    }

    if (['advanced', 'experiment'].includes(reportType)) {
      if ('azimuth' in selectedElements[0]) {
        const strokeProfileItem = {
          title: 'STROKE PROFILE',
          chart: strokeProfile,
          profile: getStrokeProfile(selectedElements),
          classification: name,
        };
        const strokeProfileRef = classRanges && classRanges.strokeProfile;
        if (strokeProfileRef) {
          strokeProfileRef.arc_length = classRanges.arc_length.mean;
          strokeProfileRef['forward-swing timing'] = classRanges['forward-swing timing'].mean;
          strokeProfileItem.reference = strokeProfileRef;
        }
        accordionItems.push(strokeProfileItem);
      }
    }

    if (['experiment', 'coach', 'basic'].includes(reportType)) {
      const validElements = selectedElements.filter(
        (d) => d['backswing timing'] !== null && d['backswing timing'] !== undefined
      );
      const bs_timing =
        validElements.length > 0 ? -d3.mean(validElements, (d) => d['backswing timing']) : null;
      const bl_timing =
        validElements.length > 0 ? -d3.mean(validElements, (d) => d['backloop timing']) : null;
      const fs_timing = -d3.mean(selectedElements, (d) => d['forward-swing timing']);
      const ft_timing = d3.mean(selectedElements, (d) => d['follow-through timing']);

      if (['experiment', 'coach'].includes(reportType)) {
        accordionItems.push({
          title: 'MOVEMENT PHASE TIMING',
          chart: motionPhases,
          phases: ['ready', 'b-swing', 'backloop', 'f-swing', 'follow-thr', 'recover'],
          tags: [{ name: 'peak acceleration', value: d3.mean(selectedElements, (d) => d.timing) }],
          times: [-2, bs_timing, bl_timing, fs_timing, 0, ft_timing, 1],
        });
      }

      if ('swing_rate_fs' in selectedElements[0] && ['experiment', 'coach'].includes(reportType)) {
        const portraitItems = d3
          .range(3)
          .map((i) => ({ width: 6, chart: phasePortrait, elements: selectedElements, mode: i }));
        accordionItems.push({
          title: 'Phase Portrait',
          component: BasicLayout,
          items: portraitItems,
        });
      }
      if (['experiment', 'coach'].includes(reportType)) {
        accordionItems.push({
          title: 'Spin-Ball Speed Map',
          width: 8,
          chart: contourSpinPace,
          elements: reducedElements,
          units,
        });
      }
    }

    if (reportType === 'experiment') {
      const metricConfig = metrics.map((group) => ({
        label: group.key,
        values: group.value
          .filter((metric) => metric.batch === false)
          .map((metric) => ({
            key: metric.name,
            label: metric.label,
            xRange: metric.range && [d3.min(metric.range), d3.max(metric.range)],
            skillParams: [0.5],
            abs: metric.abs,
            scale: metric.scale,
            unit: metric.unit,
          })),
      }));

      accordionItems.push({
        title: 'Distributions',
        component: PhaseDistribution,
        elementsSession: selectedElements,
        elementsAll: classElements,
        specifiedClass: name,
        config: metricConfig,
      });
    }
    return <Accordion items={accordionItems} mode={0} classes="grey-bg" />;
  };

  const navContent =
    counts[counts.length - 1] < MIN_COUNT
      ? {
          content: (
            <Container className="padding-bottom-25 animated fadeIn">
              <Row>
                <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
                  <hr className="colorgraph" />
                  <h3 className="padding-top-25 text-center">No sufficient strokes</h3>
                </Col>
              </Row>
            </Container>
          ),
        }
      : { component: NavPane, items: navbarItems, contentOptions };

  return [
    {
      width: 3,
      component: Sidebar,
      items: sidebarItems,
      sticky: 100,
      activeContent: name,
      onTransition,
    },
    {
      width: 9,
      items: [
        [
          {
            component: BasicHeader,
            classes: 'blue',
            title: `${idInGroup + 1}. ${typeConversion[activeType]} ${abrName}`,
          },
        ],
        [
          {
            component: OverviewTable,
            items: overviewTableItems,
            useState: 'filteredSets',
          },
        ],
        [
          {
            component: Accordion,
            mode: 0,
            items: [
              {
                title: 'Class Score & Skill Status History',
                chart: classSummaryTrend,
                elements: classElements,
                classKey,
              },
            ],
          },
        ],
        [{ content: <div className="pr-2 pt-4">REFERENCE: </div>, classes: 'padding-top-25' }],
        [
          {
            component: ComparisonSelectionV2,
            id: refId,
            toggleComparison: data.useRefId,
            comparisons: availableClassRanges.map(
              (d) => d.group + (d.group === classRankingLevels[level] ? '*' : '')
            ),
          },
        ],
        [navContent],
      ],
    },
  ];
}
